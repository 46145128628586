import React from "react"
import Layout from "../components/common/layout"
import CookieContent from "../components/cookie/cookieContent"

const title = `Cookie-Einstellungen`

const CookiePage = () => (
  <Layout title={title} >
    <CookieContent />
  </Layout>
)

export default CookiePage
