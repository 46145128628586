import React from "react"
import SliderToggle from "./sliderToggle";
import Cookies from "universal-cookie"
import styles from './cookieToggle.module.css'

export default class CookieToogle extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.cookieName = props.cookieName;
    this.text = props.text;
    this.state = {
      isChecked: this.cookies.get(this.cookieName) === `true`
    };
    this.setCookie = this.setCookie.bind(this);
  }

  setCookie(state) {
    this.cookies.set(this.cookieName, state);
    // reload the force restart of facebook tracking (such that it recognized the change)
    window.location.href = '/cookie';
  }

  render() {
    return (  
      <div className={styles.wrapper}>
        <div>
          <SliderToggle 
            isChecked={this.state.isChecked}
            handleClick={this.setCookie}
          />
        </div>
        <div className={styles.text}>
          {this.text}
        </div>
      </div>
    )
  }
}