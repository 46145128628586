import React from "react"
import CookieToogle from "./cookieToggle";
import config from "../../../data/SiteConfig";

export default class CookieContent extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (  
      <div>
        <h3>Cookie-Einstellungen</h3>
        <CookieToogle 
          cookieName={config.trackingConsentCookie}
          text={`Marketing Cookies`}
        />
      </div>
    )
  }
}