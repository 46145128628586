import React from "react"
import styles from './sliderToggle.module.css'

export default class SliderToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked,
    };
    this.handleClick = props.handleClick;
    
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange() {
    var newState = !this.state.isChecked;
    this.setState({ isChecked: !this.state.isChecked });
    this.handleClick(newState);
  }
  render () {
    return (
      <label className={styles.switch}>
        <input type="checkbox" checked={this.state.isChecked} onChange={this.handleChange} />
        <div className={styles.slider}></div>
      </label>
    );
  }
}
